import { breakpoints } from "@tvg/design-system";
import styled from "styled-components";

export const Section = styled.div`
  background-color: var(--fd-colors-core-white);
  padding: var(--fd-space-space-5);
  border-radius: var(--fd-radii-border-radius-020);
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  margin-top: var(--fd-space-space-5);
  display: flex;
`;

export const InputSelectContainer = styled.div<{ isMobile: boolean }>`
  width: ${(props) => (props.isMobile ? "100%" : "50%")};
  z-index: 1;
`;

export const ToggleContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;

  [data-qa-label="switch-spinner"] {
    margin-right: var(--fd-space-space-2);
  }
`;

export const PreviewSection = styled.div`
  margin-top: var(--fd-space-space-4);
  margin-bottom: var(--fd-space-space-5);

  @media screen and ${breakpoints.tablet.max.sm} {
    margin-top: 0;
  }
`;

export const HPSelectorTitle = styled.div`
  margin-bottom: var(--fd-space-space-2);

  @media screen and ${breakpoints.tablet.min.sm} {
    margin-bottom: var(--fd-space-space-4);
  }
`;

export const ToggleSelectorSection = styled.div`
  background-color: var(--fd-colors-background-surface);
  margin-bottom: var(--fd-space-space-3);
  padding: var(--fd-space-space-5);

  @media screen and ${breakpoints.tablet.min.sm} {
    padding: var(--fd-space-space-3);
    padding-bottom: 0;
  }
`;

export const ApplyButtonContainer = styled.div`
  position: sticky;
  bottom: 0;
  width: 100%;
  box-shadow: 0 -2px 4px 0 rgba(10, 10, 10, 0.1);
  background-color: var(--fd-colors-background-surface);
  padding: var(--fd-space-space-4) 0;
  display: flex;
  justify-content: center;

  @media only screen and (hover: hover) and (pointer: fine) and ${breakpoints
      .tablet.min.sm} {
    button {
      max-width: calc(100% - 320px);
    }
  }

  @media screen and ${breakpoints.tablet.max.sm} {
    padding: var(--fd-space-space-3);
    padding-bottom: max(var(--fd-space-space-3), env(safe-area-inset-bottom));
  }
`;
